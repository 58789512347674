import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import Carousel from './components/Carousel';
import GetInTouch from './components/GetInTouch';
import Footer from './components/Footer';

function App() {
  return (
    <>
      <Header />
      <Carousel />

      {/* //play with id smartly otherwise it will throw err of below code! */}
      <div id="property">
      <br></br><br></br><br></br>
      <p class="text-red-700">The quick brown fox...</p>

      {/* <h2>Hello</h2> */}
      <h1>This is Project- Y</h1>
      <h1 className="text-3xl font-bold underline">
      Here will be toggle button with dynamic content of tennant and owners
      </h1>
      <br></br><br></br><br></br>


      </div>

      <GetInTouch />
      <Footer />
    </>
    
  );
}

export default App;
