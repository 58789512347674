import React from "react";
import twitter_icon from "../assets/twitter.png";
import facebook_icon from "../assets/facebook_1.png";
import instagram_icon from "../assets/instagram_1.png";
import telegram_icon from "../assets/telegram.png";

const Footer = () => {
  return (
    <footer className="bg-black text-white py-16 px-6 md:px-18 flex flex-col gap-8 lg:gap-16">
      {/* Main Footer Content */}
      <div id="about" className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {/* Find Us Section */}
        <div className="flex flex-col gap-8">
          <h3 className="text-xl font-semibold">Find Us</h3>
          <div className="flex flex-col gap-6">
            <p className="leading-relaxed">
            G105, Block G, Rail Vihar
              <br />
              Sector 57, Gurugram
              <br />
              Haryana 122011
            </p>
            <p>contact@operationY.com</p>
          </div>
        </div>

        {/* Property Section */}
        {/* <div className="flex flex-col gap-8">
          <h3 className="text-xl font-semibold">Property</h3>
          <div className="flex flex-col gap-6">
            <p>Apartments</p>
            <p>Villa's</p>
            <p>Houses</p>
            <p>Commercial</p>
          </div>
        </div> */}

        {/* Links Section */}
        <div className="flex flex-col gap-8">
          <h3 className="text-xl font-semibold">Links</h3>
          <div className="flex flex-col gap-6">
          <a href="#home">Home</a>
        {/* <a href="#property">Property</a> */}
        <a href="#about">About</a>
        <a href="#contact">Contact</a>
          </div>
        </div>

        {/* Newsletter Section */}
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-8">
            <h3 className="text-xl font-semibold">Newsletter</h3>
            <p>Subscribe to our newsletter</p>
          </div>
          <div className="bg-white rounded-xl p-1.5 flex items-center xs:w-auto sm:w-auto md:w-[400px]">
            <input
              type="email"
              placeholder="Your email"
              className="flex-1 px-3 text-gray-500 outline-none"
            />
            <button className="bg-blue-600 text-white px-3 py-1.5 rounded-lg text-sm">
              Subscribe
            </button>
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="border-t border-white/60 pt-5 flex flex-col md:flex-row justify-between items-center gap-4">
        <p className="text-white/50">©Copyright Operation Y {new Date().getFullYear()}.</p>
        
        {/* Social Media Icons */}
        <div className="flex gap-3">
          {/* Facebook */}
          <div className="w-5 h-5 bg-white rounded-full flex items-center justify-center">
            {/* <div className="w-1.5 h-3 bg-contain bg-no-repeat bg-center" style={{ backgroundImage: "url('../assets/facebook.png')" }} /> */}
            <img src={facebook_icon} alt="Facebook" className="w-3 h-3" />
          </div>

          {/* Twitter */}
          <div className="w-5 h-5 bg-white rounded-full flex items-center justify-center">
            <img src={twitter_icon} alt="Twitter" className="w-3 h-3" />
          </div>

          {/* Instagram */}
          <div className="w-5 h-5 bg-white rounded-full flex items-center justify-center p-0.5">
            <div className="w-3.5 h-3.5 relative">
              <div className="w-3 h-3 absolute top-0.5 left-0.5">
                {/* <img src="../assets/facebook_icon.svg" alt="Instagram" className="w-1 h-1 absolute top-1 left-1" /> */}
            <img src={instagram_icon} alt="Instagram" className="w-3 h-3" />

              </div>
            </div>
          </div>

          {/* Telegram */}
          <div className="w-5 h-5 bg-white rounded-full flex items-center justify-center">
            {/* <div className="w-3 h-2.5 bg-contain bg-no-repeat bg-center mt-1" style={{ backgroundImage: "url('../assets/Telegram_black.svg')" }} /> */}
            <img src={telegram_icon} alt="Twitter" className="w-3 h-3" />

          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;