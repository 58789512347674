// import React from "react";
import y_icon from "../assets/y_icon.svg";
import profile_icon from "../assets/profile_icon.svg";

import "./header.css";
// import "./style.css";
import React, { useState, useEffect } from 'react';
import { Menu, X } from "lucide-react";

function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [activeLink, setActiveLink] = useState('home');

    const menuItems = [
        { text: 'Home', id: 'home' },
        { text: 'Property', id: 'property' },
        { text: 'Contact', id: 'contact' },
        { text: 'About', id: 'about' }
      ];

      useEffect(() => {
        const handleScroll = () => {
            const sections = menuItems.map(item => document.getElementById(item.id));
            const scrollPosition = window.scrollY + window.innerHeight / 4;
          
            let foundActiveLink = false;
          
            sections.forEach(section => {
              if (section && section.offsetTop <= scrollPosition && section.offsetTop + section.offsetHeight > scrollPosition) {
                setActiveLink(section.id);
                section.classList.add('active-section'); // Add active class
                foundActiveLink = true;
              } else if (section) {
                section.classList.remove('active-section'); // Remove active class
              }
            });
          
            // If no section is found and the scroll position is at the top, set the active link to "home"
            if (!foundActiveLink && window.scrollY === 0) {
              setActiveLink('home');
              document.getElementById('home').classList.add('active-section'); // Add active class to home
            }
        };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [menuItems]);

//   const handleLinkClick = (id) => {
//     setActiveLink(id);
//     document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
//   };
  
    return (
        // <nav className="w-full px-4 md:px-6 lg:px-8">
        <nav className="fixed top-0 w-full z-50 bg-white shadow-md">
            <div className="mx-auto max-w-7xl">
                <div className="flex h-16 md:h-[72px] items-center justify-between">
                    {/* Logo Section */}
                    <div className="flex items-center gap-1">
                        <img className="w-6 h-6" alt="Icon" src={y_icon} />
                        <div className="flex items-center pt-1.5">
                            <div className="text-black text-base font-semibold whitespace-nowrap font-gantari">
                                Operation Y
                            </div>
                        </div>
                    </div>

                    {/* Desktop Menu */}
                    <div className="hidden md:flex items-center gap-8 lg:gap-14">
                    {menuItems.map((item) => (
                    <div 
                        key={item.id} 
                        className="flex items-center justify-center"
                    >
                        <a 
                        href={`#${item.id}`} 
                        className={`text-black text-base ${
                            activeLink === item.id ? 'font-bold underline' : 'font-medium'
                        } whitespace-nowrap font-gantari hover:text-gray-600 transition-colors`}
                        // onClick={(e) => {
                        //     e.preventDefault();
                        //     handleLinkClick(item.id);
                        // }}
                        >
                        {item.text}
                        </a>
                    </div>
                    ))}
                    </div>

                    {/* Login Button */}
                    <div className="hidden md:flex">
                        <div className="inline-flex items-center bg-[#0056ff] hover:bg-blue-600 transition-colors rounded-3xl px-3 py-2">
                            <div className="flex items-center gap-2">
                                <img className="w-6 h-6" alt="Icon" src={profile_icon} />
                                <div className="text-white text-base font-medium whitespace-nowrap font-gantari">
                                    SignIn / Up
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Mobile Menu Button */}
                    <div className="md:hidden">
                        <button
                            onClick={() => setIsMenuOpen(!isMenuOpen)}
                            className="inline-flex items-center justify-center p-2 rounded-md text-black hover:text-gray-600 hover:bg-gray-100 transition-colors"
                        >
                            {isMenuOpen ? (
                                <X className="block h-6 w-6" />
                            ) : (
                                <Menu className="block h-6 w-6" />
                            )}
                        </button>
                    </div>
                </div>

                {/* Mobile Menu Panel */}
                {isMenuOpen && (
                    <div className="md:hidden">
                        <div className="px-2 pt-2 pb-3 space-y-1 bg-white shadow-lg rounded-lg mt-2">
                            {menuItems.map((item, index) => (
                                <a
                                    key={item.id} // Changed from item to item.id
                                    href={`#${item.id}`} 
                                    className={`block px-3 py-2 rounded-md text-base font-medium ${
                                        activeLink === item.id
                                            ? 'text-blue-600 bg-blue-50'
                                            : 'text-black hover:text-gray-600 hover:bg-gray-50'
                                    } transition-colors`}
                                >
                                    {item.text}
                                </a>
                            ))}
                            {/* Mobile Login Button */}
                            <div className="px-3 py-2">
                                <button className="w-full flex items-center justify-center gap-2 bg-[#0056ff] hover:bg-blue-600 text-white rounded-3xl px-3 py-2 transition-colors">
                                    <img className="w-6 h-6" alt="Icon" src={profile_icon} />
                                    <span className="font-medium">SignIn / Up</span>
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </nav>
    );
}

export default Header;
